import React from 'react';
import { useHistory } from 'react-router-dom';
import { middleSchoolTheme } from '@twigeducation/ts-fe-components';
import useTranslation from '../../hooks/useTranslation';
import { StyledPageError } from './NotFound.styled';

const NotFound = () => {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <StyledPageError
            theme={middleSchoolTheme}
            title={t('not_found_page.title', "Oops! We can't find what you're looking for!")}
            body={t('not_found_page.message', "Sorry, this page doesn't exist.")}
            buttonConfig={{
                label: t('not_found_page.button_label', 'Go to my Dashboard'),
                onClick: () => history.push('/'),
            }}
        />
    );
};

export default NotFound;
