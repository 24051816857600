import { AsyncComponent } from '@twigeducation/async-component';
import React from 'react';
import { Route } from 'react-router-dom';
import WithExternalRedirect from '../../components/withExternalRedirect';
import Layout from '../../layout';

const Integrations = props => (
    <AsyncComponent
        csvRosteringJobServiceUrl={window.config.CSV_ROSTERING_JOB_SERVICE_API}
        s3UploadServiceUrl={window.config.S3_UPLOAD_SERVICE_API}
        rosteringBucketName={window.config.BUCKETS.ROSTERING}
        oidcProviderUrl={window.config.OIDC_PROVIDER_URL}
        ssoClientId={window.config.SSO_CLIENT_ID}
        appName="ts-integrations"
        componentName="Integrations"
        {...props}
    />
);

const allowedRoles = ['DISTRICT_OWNER', 'SCHOOL_ADMIN', 'TWIG_ADMIN'];
const routes = [
    <Route
        path="/integrations/*"
        component={() => (
            <WithExternalRedirect roles={allowedRoles}>
                <Integrations Layout={Layout} />
            </WithExternalRedirect>
        )}
        key="integrations"
    />,
];

export default routes;
