import { v4 as uuidv4 } from 'uuid'; // For generating unique trace IDs
import * as Sentry from '@sentry/react';

export const setHeader = ({ headers = {} }) => {
    const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
    const traceID = transaction ? transaction.traceId : `middle-school/${uuidv4()}`;
    return {
        headers: {
            ...headers,
            'x-request-id': traceID,
        },
    };
};

export default (operation, forward) => {
    operation.setContext(setHeader);
    return forward(operation);
};
