import { ApolloClient, ApolloLink, from as compileLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import userManager from '../Authentication/userManager';
import setRequestID from './middleware/setRequestID';
import setProductVariantCode from './middleware/setProductVariantCode';
import possibleTypes from '../../../possibleTypes.json';

const graphqlUrl = window.config.CREATE_GRAPHQL_URL_FROM_HOSTNAME
    ? `${window.location.protocol}//${window.location.host}/svc/graphql-gateway/graphql`
    : window.config.PUBLIC_GRAPHQL_URL;

const httpLink = new BatchHttpLink({
    uri: graphqlUrl,
    credentials: 'omit',
});

const OIDCAuthHeadersLink = setContext(() =>
    userManager.getUser().then(user => {
        if (user?.id_token) {
            return {
                headers: {
                    authorization: `Bearer ${user.id_token}`,
                },
            };
        }
        return {};
    }),
);

const links = [OIDCAuthHeadersLink, new ApolloLink(setRequestID), new ApolloLink(setProductVariantCode), httpLink];

const link = compileLink(links);

const client = new ApolloClient({
    cache: new InMemoryCache({
        possibleTypes,
        typePolicies: {
            Cloudinary: {
                keyFields: ['publicId'],
            },
        },
    }),
    link,
    name: 'ts-admin',
    uri: graphqlUrl,
    version: window.config.IMAGE_GIT_COMMIT_SHA,
});

export default client;
